<template>
  <v-hover v-if="isVisible" v-slot="{ hover }">
    <v-sheet :elevation="hover ? 12 : 6" rounded="xl" href="" :style="cssVars" color="white" class="flex-grow-1 relative align-stretch" min-height="200px">
      <!-- <v-row class="py-0 flex-column" align="center">
        <v-row class="px-10 w-70">
          <p class="text-h3 mb-0 white--text"> <slot name="title"></slot><br> </p>
          <p class="white--text mb-0"> <slot name="subtitle"></slot><br> </p>
        </v-row>
        <v-row class="w-30">
          <video playsinline autoplay muted loop class="video" width="100%">
            <slot name="video"></slot>
          </video>
        </v-row>
      </v-row> -->
      <v-col cols="8" class="px-0 py-4" align-self="center">
        <v-row class="px-10">
          <p class="text-h4 text-sm-h3 mb-4 mb-sm-0 white--text font-weight-semibold"> <slot name="title"></slot><br> </p>
          <p class="white--text mb-0"> <slot name="subtitle"></slot><br> </p>
        </v-row>
      </v-col>
      <v-col cols="" class="pa-0">
        <video playsinline autoplay muted loop class="video object-cover" width="100%">
          <slot name="video"></slot>
        </video>
      </v-col>
    </v-sheet>
  </v-hover>
</template>

<script>
import ConditionalRoleVisibility from "@/mixins/ConditionalRoleVisibility.vue"
export default {
  mixins: [ConditionalRoleVisibility],
  props: {
    color: {
      type: String,
      required: true
    },
    gif: {
      type: String,
      required: true
    }
  },
  computed: {
    cssVars() {
      return {
        "--tile-color": `#${this.color}`,
        "--tile-gif": `url("${this.gif}")`
      };
    }
  }
};
</script>

<style lang="scss" scoped>

a {
  text-decoration: none;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.w-70 {
  width: 70%;
}
.v-sheet {
  background-color: var(--tile-color) !important;
  // background: url("~@/assets/img/animacion_1.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: .4s;
  overflow: hidden;
  
  .tile-title {
    color: #f0f0f0 !important;
  }
}

.video-frame {
  background: red;
}
.video {
  height: 100%;
  width: 100%;
}
</style>
