<script>
import {mapGetters} from "vuex"

export default {
    name: "ConditionalRoleVisibility",
    props: {
        visibleFor: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapGetters("auth", ["strapiUserData"]),
        isVisible(){
            return this.visibleFor.includes(this.strapiUserData.type)
        }
    }
}
</script>