<template>
  <v-app>
    <dashboard-core-app-bar v-model="expandOnHover" />

    <v-parallax
      src="~@/assets/img/senora_parallax.jpg"
      height="500"
      class="px0"
      id="home-parallax"
    >
      <v-row>
        <img src="~@/assets/img/onda1.png" alt="" class="pa-0" id="onda1" />
        <img src="~@/assets/img/onda2.png" alt="" class="pa-0" id="onda2" />
      </v-row>
    </v-parallax>

    <v-main class="pl-sm-16 pb-16">
      <v-container class="transition">
        <v-row justify="center">
          <v-col cols="12" lg="10" class="transition">
            <v-row justify="" class="pa-6 pt-0 pa-md-0">
              <h1 class="text-h1 darkprimary--text">
                {{ $t("titulo home") }}
              </h1>
            </v-row>
            <v-row class="px-6 px-md-0">
              <p>
                {{
                  $t(
                    "Mylogy es la Plataforma Digital de Medicina Genómica, para particulares y profesionales, de EuroEspes Health."
                  )
                }}
              </p>
            </v-row>
            <v-row class="pa-0">
              <v-col cols="12" sm="6" class="pl-sm-0">
                <tile-button
                  :visibleFor="['basic', 'pro', 'advanced', 'ADMIN']"
                  v-on:click.native="$router.push({ name: 'GeneticProfile' })"
                  color="6F4AE6"
                >
                  <template v-slot:video>
                    <source
                      src="~@/assets/img/card_animation.webm"
                      type="video/webm"
                    />
                    <source
                      src="~@/assets/img/card_animation.mp4"
                      type="video/mp4"
                    />
                  </template>
                  <template v-slot:icon>
                    <v-icon size="60" color="#ffffff" class="">mdi-dna</v-icon>
                  </template>
                  <template v-slot:title>
                    <span>{{ $t("perfil_genetico") }}</span>
                  </template>
                  <template v-slot:subtitle>
                    <span>{{
                      $t(
                        "Nuestro Análisis Farmacogenético es el estudio más completo a nivel mundial hoy en día."
                      )
                    }}</span>
                  </template>
                </tile-button>
              </v-col>
              <v-col cols="12" sm="6" class="pr-sm-0">
                <tile-button
                  :visibleFor="['basic', 'pro', 'advanced', 'ADMIN']"
                  v-on:click.native="$router.push({ name: 'PGXResults' })"
                  color="381273"
                >
                  <template v-slot:video>
                    <source
                      src="~@/assets/img/pharmacogenetic_animation.webm"
                      type="video/webm"
                    />
                    <source
                      src="~@/assets/img/pharmacogenetic_animation.mp4"
                      type="video/mp4"
                    />
                  </template>
                  <template v-slot:icon>
                    <v-icon size="60" color="#ffffff" class="">mdi-pill</v-icon>
                  </template>
                  <template v-slot:title>
                    <span>{{ $t("farmacogenetica") }}</span>
                  </template>
                  <template v-slot:subtitle>
                    <span>{{
                      $t(
                        "Comprueba cómo se relacionan los fármacos con tu genética."
                      )
                    }}</span>
                  </template>
                </tile-button>
              </v-col>
            </v-row>
            <v-row class="pa-0">
              <v-col cols="12" sm="7" class="pl-sm-0">
                <tile-button
                  :visibleFor="['pro', 'basic', 'advanced', 'ADMIN']"
                  v-on:click.native="$router.push({ name: 'Explorer' })"
                  color="1C093A"
                >
                  <template v-slot:icon>
                    <v-icon size="60" color="#ffffff" class="">mdi-book</v-icon>
                  </template>
                  <template v-slot:title>
                    <span>{{ $t("EPG") }}</span>
                  </template>
                  <template v-slot:subtitle>
                    <span>{{ $t("resumen_epg") }}</span>
                  </template>
                  <v-row justify="center">
                    <v-btn rounded color="white">{{ $t("entrar") }}</v-btn>
                  </v-row>
                  <template v-slot:video>
                    <source
                      src="~@/assets/img/libro_animation.webm"
                      type="video/webm"
                    />
                    <source
                      src="~@/assets/img/libro_animation.mp4"
                      type="video/mp4"
                    />
                  </template>
                </tile-button>
              </v-col>
              <v-col cols="12" sm="5" class="pr-sm-0">
                <tile-button
                  :visibleFor="['pro', 'basic', 'advanced']"
                  v-on:click.native="$router.push({ name: 'InfoPage' })"
                  color="41B284"
                >
                  <template v-slot:icon>
                    <v-icon size="60" color="#ffffff" class="">mdi-book</v-icon>
                  </template>
                  <template v-slot:title>
                    <span>{{ $t("more_info") }}</span>
                  </template>
                  <template v-slot:subtitle>
                    <span>{{ $t("more_info_description") }}</span>
                  </template>
                  <template v-slot:video>
                    <source
                      src="~@/assets/img/info_animation.webm"
                      type="video/webm"
                    />
                    <source
                      src="~@/assets/img/info_animation.mp4"
                      type="video/mp4"
                    />
                  </template>
                </tile-button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TileButton from "@/components/mylogy/TileButton.vue";

export default {
  name: "DashboardIndex",

  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    TileButton
  },
  methods: {
    goToExternal(externalURL) {
      window.open(externalURL, "_blank");
    }
  },
  data: () => ({
    expandOnHover: false
  }),
  computed: {},
  mounted() {}
};
</script>

<style lang="scss">
.transition {
  transition: 0.5s !important;
}

// PARALLAX
#onda1,
#onda2 {
  position: absolute;
  bottom: 0;
  width: calc(100vw + 200px);
  min-width: 800px;
  animation-iteration-count: infinite;
  animation-name: animacion;
  animation-direction: alternate-reverse;
}
#onda1 {
  animation-duration: 15s;
  max-height: 70px;
}
#onda2 {
  animation-duration: 10s;
  max-height: 50px;
}

@keyframes animacion {
  from {
    left: -100px;
  }
  to {
    left: 0px;
  }
}
// parallax

#europharma {
  background: #1c093a;
  position: fixed;
  bottom: 0;
}
@media (max-width: 900px) {
  #europharma {
    position: sticky;
    bottom: -25%;
  }
}
a {
  text-decoration: none;
}

#panel-selector {
  .v-card {
    height: 400px;
    width: calc(33% - 33px);
    min-width: min-content;
    border: solid 2px #6f4be0;
    overflow: hidden;

    .title {
      font-size: calc(2em + 10%) !important;
    }

    .v-card__actions {
      height: 20%;

      .v-btn__content {
        color: #6f4be0 !important;
        font-weight: 500;
      }
    }
    .card-shape {
      position: absolute;
      background: #1c093a;
      width: 120%;
      height: 160px;
      bottom: -60px;
      //transform: rotate(-7deg);
      border-radius: 50%;
    }
  }
  .v-card * {
    word-break: normal;
  }
  .v-card:nth-child(1) {
    background: url("~@/assets/img/perfil-tarxeta.png") no-repeat bottom;
    background-position-y: 150px;
    background-size: 80%;
    transition: 0.3s;
    &:hover {
      background-position-y: 120px;
    }
  }
  .v-card:nth-child(2) {
    background: url("~@/assets/img/farmacogenetica-tarxeta.png") no-repeat
      bottom;
    background-position-y: 130px;
    background-size: 90%;
    transition: 0.3s;
    &:hover {
      background-position-y: 100px;
    }
  }
  .v-card:nth-child(3) {
    background: url("~@/assets/img/guia-tarxeta.png") no-repeat bottom;
    background-position-y: 130px;
    background-size: 100%;
    transition: 0.3s;
    &:hover {
      background-position-y: 100px;
    }
  }
}
#tile_1 {
  background: url(http://localhost:8080/img/gif_1.18fb27d3.gif);
}
</style>
